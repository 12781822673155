import { Box, IconButton, Modal } from '@material-ui/core';
import { ArrowForwardIos, WarningOutlined } from '@material-ui/icons';
import CachedIcon from '@material-ui/icons/Cached';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import {
    AddScribeToAllMeetingKind,
    CalendarEvent,
    CalendarEventType,
    ClientEventType,
    WebUrlQuery,
} from '@spinach-shared/types';
import {
    StoredSpinachSeries,
    TimeUtils,
    doCalUidsBelongToTheSameEventSeries,
    getCalUidPrefix,
    getMasterEventId,
    getUniques,
} from '@spinach-shared/utils';

import { ReactComponent as FacilicationViz } from '../../../../assets/facilitation-viz.svg';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalRouting,
    useIsNonOrganizerAllowedToRemoveSpinach,
    usePrevious,
    useWindowSize,
} from '../../../../hooks';
import { useCalendarEvents, useUpdateScribeOnEventWithOverrides } from '../../../../hooks/useCalendarEvents';
import { useGlobalAiMeetingSelection } from '../../../../hooks/useGlobalAiMeetingSelection';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { useSchedulingAutomationInProgressState } from '../../../../hooks/useSchedulingAutomationInProgressState';
import { isScribeEmail } from '../../../../hooks/useScribe';
import {
    BodyRegularOnboard,
    BodyRegularOnboardWithTextWrap,
    HeaderThree,
    HeaderThreeOnboard,
    lightTheme,
} from '../../../../styles';
import { SetValue } from '../../../../types';
import { URLUtil } from '../../../../utils/URLUtil';
import { ClientPath, Column, LoadingSquares, Row, Spacing, SpinachModalContent } from '../../../common';
import { PrimaryButton, ScrollArea } from '../../../stand-up';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { isScribeOnEvent } from '../../ScribeCalendarPage';
import { SummaryProcessingDisabledBanner } from '../AccountManagement/PlanBanner';
import { IntegrationSettingsModalKind } from '../types';
import { CombinedMeetingDetails, useOpenInMeetingApp } from './CombinedMeetingDetails';
import { MeetingAutomationDropDown } from './MeetingAutomationDropDown';
import './MeetingSection.css';
import { OnboardingSetupPromo } from './OnboardingSetupPromo';
import { PrepHostForFirstMeeting } from './PrepHostForFirstMeeting';

const PAGE_SIZE = 20;

const useSetInitialPage = (
    setPage: SetValue<number>,
    hasSetInitialPage: React.MutableRefObject<boolean>,
    validCalendarEvents?: CalendarEvent[]
) => {
    const { state } = useGlobalAiDashboard();
    useEffect(() => {
        if (hasSetInitialPage.current) {
            return;
        }

        setPage(() => {
            if (!validCalendarEvents?.length) {
                return 0;
            }

            if (!state.pendingSpinachToggleICalUid) {
                hasSetInitialPage.current = true;
                return 0;
            }

            const pendingEventIndex = validCalendarEvents.findIndex((event) => {
                const hasEventAlreadyPast = Boolean(
                    event.end?.dateTime &&
                        moment(event.end.dateTime)
                            .tz(event.end.timeZone ?? TimeUtils.getTimezoneRegion())
                            .isBefore(moment().tz(TimeUtils.getTimezoneRegion()))
                );
                return event.iCalUID === state.pendingSpinachToggleICalUid && !hasEventAlreadyPast;
            });

            if (pendingEventIndex === -1) {
                hasSetInitialPage.current = true;
                return 0;
            }

            hasSetInitialPage.current = true;
            return Math.floor(pendingEventIndex / PAGE_SIZE);
        });
    }, [validCalendarEvents, state.pendingSpinachToggleICalUid, setPage]);
};

function RemoveConfirmationModal({
    isOpen,
    onClose,
    onClick,
}: {
    isOpen: boolean;
    onClose: () => void;
    onClick: () => void;
}) {
    return (
        <Modal open={isOpen} onClose={onClose}>
            <SpinachModalContent
                style={{
                    overflowY: 'unset',
                    maxHeight: 'unset',
                    minHeight: 'unset',
                    height: '350px',
                }}
                onClose={onClose}
            >
                <Spacing />
                <Column
                    style={{
                        flexGrow: 1,
                        padding: '15px',
                        borderRadius: '4px',
                    }}
                >
                    <Column style={{ flexGrow: 1, paddingLeft: '24px' }}>
                        <Row style={{ alignItems: 'end' }}>
                            <WarningOutlined
                                htmlColor={lightTheme.primary.orangeLight}
                                style={{ paddingRight: '3px' }}
                            />
                            <HeaderThree style={{ display: 'contents', verticalAlign: 'bottom' }}>
                                Are you sure?
                            </HeaderThree>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row>
                            <span style={{ width: '24px', paddingRight: '3px' }} />
                            <BodyRegularOnboard style={{ width: '100%' }}>
                                You’re not the organizer of this meeting.
                            </BodyRegularOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row>
                            <span style={{ width: '24px', paddingRight: '3px' }} />
                            <BodyRegularOnboard style={{ width: '100%' }}>
                                If you remove Spinach, your team will no longer receive summaries for all future
                                meetings in this series.
                            </BodyRegularOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row>
                            <span style={{ width: '24px', paddingRight: '3px' }} />
                            <BodyRegularOnboard style={{ width: '100%' }}>
                                Are you sure you want to remove Spinach?
                            </BodyRegularOnboard>
                        </Row>
                    </Column>
                    <Row centered>
                        <PrimaryButton title="Nevermind" onClick={onClose} />
                        <Spacing factor={1 / 2} horizontal={true} />
                        <SecondaryButton title="Remove Spinach" onClick={onClick} />
                    </Row>
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}

function SchedulingAutomationInProgressView() {
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <LoadingSquares />
            <Spacing factor={1 / 2} />
            <HeaderThreeOnboard style={{ fontSize: '24px' }}>
                Configuring Spinach for your upcoming meetings.
            </HeaderThreeOnboard>
            <Spacing factor={1 / 2} />
            <BodyRegularOnboard>Please wait while we get things ready.</BodyRegularOnboard>
        </div>
    );
}

function UpdatingAutoRecordSettingsView() {
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <LoadingSquares />
            <Spacing factor={1 / 2} />
            <HeaderThreeOnboard style={{ fontSize: '24px' }}>Updating your meeting settings.</HeaderThreeOnboard>
            <Spacing factor={1 / 2} />
            <BodyRegularOnboard>
                This may take a minute. You can explore your dashboard while we work on this.
            </BodyRegularOnboard>
        </div>
    );
}

export function CombinedMeetingSection({
    storedSeriesList,
}: {
    storedSeriesList: StoredSpinachSeries[];
    setStoredSeriesList: (value: StoredSpinachSeries[]) => void;
    setModalKind: (modalKind: IntegrationSettingsModalKind | null) => void;
}): JSX.Element {
    const track = useExperienceTracking();
    const { width } = useWindowSize();
    const [user] = useGlobalAuthedUser();
    const [searchParams] = useSearchParams();
    const deeplinkedEventId = searchParams.get(WebUrlQuery.EventId);
    const [scrolledToEvent, setScrolledToEvent] = useState(false);
    const { schedulingAutomationInProgressState } = useSchedulingAutomationInProgressState();

    const {
        openedMeetingsState: { openMeetingDetails },
        openMeetingByCalEventId,
        closeMeetingByCalEventId,
    } = useGlobalAiMeetingSelection();

    useEffect(() => {
        if (deeplinkedEventId && !scrolledToEvent) {
            openMeetingByCalEventId(deeplinkedEventId);
            // scroll to the event
            const eventElements = document.getElementsByClassName(`${WebUrlQuery.EventId}-${deeplinkedEventId}`);
            if (eventElements.length > 0) {
                eventElements[0].scrollIntoView({ behavior: 'smooth' });
                setScrolledToEvent(true);
            }
        }
    }, [deeplinkedEventId, openMeetingByCalEventId, scrolledToEvent]);

    const { data, queryKey, refetch, isRefetching, isFetching } = useCalendarEvents({
        timeMin: moment.tz(TimeUtils.getTimezoneRegion()).startOf('day').toISOString(),
        timeMax: moment.tz(TimeUtils.getTimezoneRegion()).add(10, 'weeks').endOf('day').toISOString(),
        timeZone: TimeUtils.getTimezoneRegion(),
        maxResults: 500,
    });

    const [onboardingEventsToAddScribeTo] = useState<CalendarEvent[]>([]);
    const [updatingEvents, setUpdatingEvents] = useState<string[]>([]);
    const [failedEvents, setFailedEvents] = useState<string[]>([]);
    const [prepUserForSpinachToJoinForEvent, setPrepUserForSpinachToJoinForEvent] = useState<CalendarEvent>();

    const { fetch, isLoading } = useStoredSeriesListFetcher({
        recurringOnly: false,
    });

    // when scheduling automation is complete, refresh the meetings
    const previousKind = usePrevious(schedulingAutomationInProgressState.kind);
    useEffect(() => {
        const refreshMeetingsAfterSchedulingAutomationIsComplete = async () => {
            const currentKind = schedulingAutomationInProgressState.kind;

            if (!!previousKind && !currentKind) {
                // wait for data to catch up
                await new Promise((resolve) => setTimeout(resolve, 1000));
                await Promise.all([refetch(), fetch()]);
            }
        };
        refreshMeetingsAfterSchedulingAutomationIsComplete();
    }, [schedulingAutomationInProgressState.kind, fetch, refetch, previousKind]);

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([refetch(), fetch()]);
        };
        fetchData();
    }, []);

    const { mutate: updateScribeOnEvent } = useUpdateScribeOnEventWithOverrides(queryKey, {
        onSuccess: async (settledEvent, _data) => {
            if (!settledEvent) {
                setFailedEvents?.((failedEvents) => getUniques([...failedEvents, getMasterEventId(_data.event)]));
                return;
            }
            // wait just a tidbit for recall / google to sync after the update before refreshing
            //TOOD: re-evaluate this for recall v2
            await new Promise((resolve) => setTimeout(resolve, 1000));
            await fetch();
            setUpdatingEvents((updatingEvents) =>
                updatingEvents.filter((id) => {
                    if (id === getMasterEventId(settledEvent)) {
                        return false;
                    }
                    return true;
                })
            );
            setUpdatingEvents((updatingEvents) =>
                updatingEvents.filter((id) => {
                    if (id === getMasterEventId(_data.event)) {
                        return false;
                    }
                    return true;
                })
            );
            if (settledEvent.id && _data.addToEvent) {
                openMeetingByCalEventId(settledEvent.id);
            }
            if (settledEvent.isScribeOnEvent) {
                if (!user.metadata.hasBeenPreppedForSpinachToJoin && !user.metadata.lastHostedAiIso) {
                    setPrepUserForSpinachToJoinForEvent(settledEvent);
                }
            }
        },
        onError: (_, _data) => {
            track(ClientEventType.FailedToUpdateMeeting, {
                RecurringCalendarEventId: getMasterEventId(_data.event),
                ICalUid: _data.event.iCalUID,
                AddToEvent: _data.addToEvent,
            });
            setFailedEvents?.((failedEvents) => getUniques([...failedEvents, getMasterEventId(_data.event)]));
            setUpdatingEvents((updatingEvents) =>
                updatingEvents.filter((id) => {
                    if (id === getMasterEventId(_data.event)) {
                        return false;
                    }
                    return true;
                })
            );
        },
    });

    const [removeModalState, setRemoveModalState] = useState<{
        isOpen: boolean;
        calendarEvent: (CalendarEvent & { iCalUID: string }) | null;
    }>({
        isOpen: false,
        calendarEvent: null,
    });

    const validCalendarEvents = data?.filter((d) => d.eventType === CalendarEventType.Default);
    const [page, setPage] = useState(0);
    const hasSetInitialPage = useRef(false);
    useSetInitialPage(setPage, hasSetInitialPage, validCalendarEvents);

    const eventsToUse = validCalendarEvents?.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE);

    const hasAnotherPage = validCalendarEvents?.length && validCalendarEvents.length > page * PAGE_SIZE + PAGE_SIZE;
    const hasPreviousPage = page !== 0;

    const isDesktopView = width > 800;

    const isNonOrganizerAllowedToRemoveSpinach = useIsNonOrganizerAllowedToRemoveSpinach();

    const toggleScribeOnEvent = (event: CalendarEvent & { iCalUID: string }) => {
        const isScribeOn = isScribeOnEvent(event);

        const iCalUID = event.iCalUID;
        if (!iCalUID) {
            return;
        }
        setUpdatingEvents(getUniques([...updatingEvents, ...[getMasterEventId(event)]]));

        updateScribeOnEvent({
            event: { ...event, iCalUID }, // Ensure Typescript correctly infers the type of the event
            // Should always be false since we are removing scribe from the event
            addToEvent: !isScribeOn,
        }); // toggle scribe on event
    };

    function groupEventsByDay(events: CalendarEvent[]) {
        const groupedEvents: { [key: string]: CalendarEvent[] } = {};
        events.forEach((event) => {
            const date = moment(event.start?.dateTime);
            const dateKey = date.format('YYYY-MM-DD');
            if (!groupedEvents[dateKey]) {
                groupedEvents[dateKey] = [];
            }
            groupedEvents[dateKey].push(event);
        });
        return groupedEvents;
    }

    function formatDateLabel(dateString: string) {
        const date = moment(dateString);
        const currentYear = moment().year();
        const formatString = date.year() === currentYear ? 'dddd, MMMM Do' : 'dddd, MMMM Do, YYYY';
        return date.format(formatString);
    }

    function mapEventRows(eventsToRender: CalendarEvent[]) {
        return eventsToRender.map((event) => {
            const associatedSeries = storedSeriesList.find((series) =>
                series.iCalUid && event.iCalUID
                    ? series.iCalUid === getMasterEventId(event) ||
                      doCalUidsBelongToTheSameEventSeries(series.iCalUid, event.iCalUID)
                    : false
            );
            return (
                <CombinedMeetingDetails
                    key={event.id}
                    calendarEvent={event}
                    isSeriesLoading={isLoading}
                    isOpen={Boolean(event.id && openMeetingDetails.includes(event.id))}
                    setIsOpen={() => {
                        if (event.id && openMeetingDetails.includes(event.id)) {
                            closeMeetingByCalEventId(event.id);
                        } else if (event.id) {
                            openMeetingByCalEventId(event.id);
                        }
                    }}
                    isOnboardingFlow={false}
                    onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                    storedSeries={associatedSeries}
                    onChange={() => {
                        const iCalUID = event?.iCalUID;
                        if (!iCalUID) {
                            return;
                        }

                        const isOrganizer = user.isUserTheOrganizer(event);
                        const isScribeOn = isScribeOnEvent(event);

                        const analyticsData = {
                            ICalUid: event.iCalUID,
                            Action: isScribeOn ? 'remove' : 'add',
                            MeetingTitle: event.summary,
                            IsCurrentUserTheOrganizer: isOrganizer,
                            IsOnboardingFlow: false,
                            AttendeeCount: event.attendees?.length,
                            ChosenScribeEmail:
                                /**
                                 * @NOTE If scribe is already on the event we show this email as being the chosen email
                                 * since we will be accepting/decling events and not adding/removing */
                                event.attendees?.find((attendee) => isScribeEmail(attendee.email))?.email,
                            CalendarProvider: user.calendarProvider,
                        };

                        track(ClientEventType.CalendarMeetingItemClick, analyticsData);

                        if (isScribeOn && !isOrganizer) {
                            if (!isNonOrganizerAllowedToRemoveSpinach) {
                                return;
                            }
                            track(ClientEventType.MeetingItemRemoveModalShown, analyticsData);
                            setRemoveModalState({ isOpen: true, calendarEvent: { ...event, iCalUID } });
                            return;
                        }

                        toggleScribeOnEvent({ ...event, iCalUID });
                    }}
                    updatingEvents={updatingEvents}
                    isError={Boolean(event.iCalUID && failedEvents.includes(getMasterEventId(event)))}
                    setFailedEvents={setFailedEvents}
                />
            );
        });
    }

    function renderGroupedEvents(eventsToRender: CalendarEvent[]) {
        const groupedEvents = groupEventsByDay(eventsToRender);
        return Object.entries(groupedEvents).map(([dateKey, events]) => (
            <React.Fragment key={dateKey}>
                <Box
                    style={{
                        padding: '5px 0',
                        marginTop: '0px',
                        marginBottom: '-5px',
                    }}
                >
                    <BodyRegularOnboardWithTextWrap
                        style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}
                    >
                        {formatDateLabel(dateKey)}
                    </BodyRegularOnboardWithTextWrap>
                </Box>
                {mapEventRows(events)}
            </React.Fragment>
        ));
    }

    const isAddToAllMeetingsEnabled = user.isEnabledForAddToAllMeetings;

    if (schedulingAutomationInProgressState.kind === AddScribeToAllMeetingKind.All) {
        return <SchedulingAutomationInProgressView />;
    } else if (schedulingAutomationInProgressState.kind) {
        return <UpdatingAutoRecordSettingsView />;
    }

    return (
        <ScrollArea sidePadding={0} style={{ width: '100%', position: isDesktopView ? 'relative' : 'absolute' }}>
            <Box marginRight={isDesktopView ? '20px' : '0px'}>
                {user.isEnabledForCombinedSummaries ? (
                    <>
                        <OnboardingSetupPromo />
                        <QuickLaunchSection
                            isLoading={isFetching}
                            validCalendarEvents={validCalendarEvents ?? []}
                            storedSeriesList={storedSeriesList ?? []}
                        />
                    </>
                ) : (
                    <></>
                )}
                <Spacing factor={1 / 2} />
                <Row vCenter>
                    <HeaderThreeOnboard style={{ textAlign: 'left' }}>Future Meetings</HeaderThreeOnboard>
                    {user.isSummaryProcessingDisabled ? (
                        <></>
                    ) : (
                        <IconButton
                            onClick={async () => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Refresh Calendar Events',
                                });
                                await Promise.all([refetch(), fetch()]);
                            }}
                            disableRipple
                            disabled={isRefetching || isLoading || isFetching}
                            className={isRefetching || isFetching ? 'refresh-upcoming-meetings' : undefined}
                        >
                            <CachedIcon htmlColor={lightTheme.primary.greenLight} fontSize="large" />
                        </IconButton>
                    )}
                </Row>
                {prepUserForSpinachToJoinForEvent ? (
                    <PrepHostForFirstMeeting onClose={() => {}} event={prepUserForSpinachToJoinForEvent} />
                ) : null}
                {user.isSummaryProcessingDisabled ? (
                    <SummaryProcessingDisabledBanner />
                ) : (
                    <>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridColumnGap="10px"
                            gridRowGap="20px"
                            style={{
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                height: 'fit-content',
                            }}
                        >
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box width="24px"></Box>
                                {isAddToAllMeetingsEnabled ? (
                                    <MeetingAutomationDropDown />
                                ) : (
                                    <BodyRegularOnboardWithTextWrap
                                        style={{
                                            textAlign: 'center',
                                            width: '100px',
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                        }}
                                    >
                                        Invite Spinach
                                    </BodyRegularOnboardWithTextWrap>
                                )}
                            </Box>
                            {eventsToUse?.length ? renderGroupedEvents(eventsToUse) : <></>}
                        </Box>
                        {eventsToUse?.length ? (
                            <>
                                {hasPreviousPage || hasAnotherPage ? (
                                    <>
                                        <Row centered style={{ marginTop: '8px', width: '80%' }}>
                                            <OutlinedButton
                                                disabled={!hasPreviousPage}
                                                style={{ marginRight: '10px' }}
                                                title="Previous"
                                                onClick={() => {
                                                    track(ClientEventType.AIDashboardClick, {
                                                        ClickedOn: 'Previous Meeting Page',
                                                    });
                                                    setPage((page) => page - 1);
                                                }}
                                            />
                                            <OutlinedButton
                                                disabled={!hasAnotherPage}
                                                title="Next"
                                                onClick={() => {
                                                    track(ClientEventType.AIDashboardClick, {
                                                        ClickedOn: 'Next Meeting Page',
                                                    });
                                                    setPage((page) => page + 1);
                                                }}
                                            />
                                        </Row>
                                        <Spacing factor={1 / 2} />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : !eventsToUse ? (
                            <></>
                        ) : (
                            <>
                                <Column>
                                    <Spacing factor={2} />
                                    <Row centered>
                                        <Column centered>
                                            <BodyRegularOnboard>
                                                It doesn't look like you have any meetings scheduled in the next few
                                                weeks.
                                            </BodyRegularOnboard>
                                            <Spacing />
                                            <BodyRegularOnboard>
                                                Schedule a meeting with your team to get started.
                                            </BodyRegularOnboard>
                                        </Column>
                                    </Row>
                                </Column>
                            </>
                        )}
                    </>
                )}
                <RemoveConfirmationModal
                    isOpen={removeModalState.isOpen}
                    onClose={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Remove Spinach Modal Close',
                        });
                        setRemoveModalState({ isOpen: false, calendarEvent: null });
                    }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Remove Spinach Modal Confirmation',
                            ICalUid: removeModalState.calendarEvent?.iCalUID,
                        });
                        if (!removeModalState.calendarEvent) {
                            setRemoveModalState({ isOpen: false, calendarEvent: null });
                            return;
                        }
                        toggleScribeOnEvent(removeModalState.calendarEvent);
                        setRemoveModalState({ isOpen: false, calendarEvent: null });
                    }}
                />
            </Box>
        </ScrollArea>
    );
}

const Shimmer = styled.div`
    position: relative;

    color: grey;
    display: inline-block;
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
    background: ${lightTheme.neutrals.gray};
    animation: shimmer 2.5s infinite;

    border-radius: 5px;
    width: 100%;
    height: 20px;

    @keyframes shimmer {
        100% {
            -webkit-mask-position: left;
        }
    }
`;

const LoadingQuickSectionPlaceholder = () => {
    const { width } = useWindowSize();
    const isDesktopView = width > 800;
    return (
        <Box
            position="relative"
            display="flex"
            flexDirection="column"
            height="85px"
            paddingTop="20px"
            paddingBottom="15px"
            paddingX="20px"
            style={{
                background: lightTheme.actionCardPlaceholder.gradient,
                cursor: 'pointer',
                marginBottom: '20px',
                marginRight: '20px',
                borderRadius: '15px',
                width: isDesktopView ? '25%' : undefined,
                flex: !isDesktopView ? '1' : undefined,
                border: `1px solid ${lightTheme.neutrals.gray}`,
            }}
        >
            <Shimmer style={{ marginBottom: '10px' }} />
            <Shimmer style={{ marginBottom: '15px', width: '30%' }} />
        </Box>
    );
};

type EventWithSeries = {
    event: CalendarEvent;
    associatedSeries: StoredSpinachSeries;
};

const pairEventsWithSeries = (events: CalendarEvent[], storedSeriesList: StoredSpinachSeries[]) => {
    return events
        .map((event) => {
            const associatedSeries = storedSeriesList.find((series) =>
                series.iCalUid && event.iCalUID
                    ? series.iCalUid === getMasterEventId(event) ||
                      getCalUidPrefix(series.iCalUid) === getCalUidPrefix(event.iCalUID)
                    : false
            );
            return {
                event,
                associatedSeries,
            };
        })
        .filter((event): event is EventWithSeries => !!event.associatedSeries);
};

const orderByDate = (a: CalendarEvent, b: CalendarEvent) => {
    const aStart = moment(a.start?.dateTime);
    const bStart = moment(b.start?.dateTime);
    return bStart.isAfter(aStart) ? -1 : 1;
};

const QuickLaunchSection = ({
    isLoading,
    validCalendarEvents,
    storedSeriesList,
}: {
    isLoading: boolean;
    validCalendarEvents: CalendarEvent[];
    storedSeriesList: StoredSpinachSeries[];
}) => {
    const sortedEventWithSeries: EventWithSeries[] = React.useMemo(
        () => pairEventsWithSeries(validCalendarEvents, storedSeriesList).sort((a, b) => orderByDate(b.event, a.event)),
        [storedSeriesList, validCalendarEvents]
    );

    const sortedEventWithSeriesWithInMeetingEnabled = sortedEventWithSeries.filter(
        (event) => event.associatedSeries?.metadata.inMeetingEnabled && isScribeOnEvent(event.event)
    );

    const maxTopUniqueSeries = 3;
    const topUniqueSeries: Record<string, { series: StoredSpinachSeries; nextEvent: CalendarEvent }> = React.useMemo(
        () =>
            sortedEventWithSeriesWithInMeetingEnabled.reduce((acc, event) => {
                if (Object.keys(acc).length >= maxTopUniqueSeries) {
                    return acc;
                }
                const seriesId = event.associatedSeries?.id;
                if (!seriesId) {
                    return acc;
                }
                return {
                    ...acc,
                    [seriesId]: {
                        series: event.associatedSeries,
                        nextEvent: event.event,
                    },
                };
            }, {}),
        [sortedEventWithSeriesWithInMeetingEnabled]
    );

    const { width } = useWindowSize();

    const isDesktopView = width > 800;
    const isEmpty = Object.keys(topUniqueSeries).length === 0;

    const openInMeetingApp = useOpenInMeetingApp();
    const track = useExperienceTracking();
    const { routeToDemoExperience } = useGlobalRouting();

    const hideWholeSectionWhenEmpty = false;
    if (hideWholeSectionWhenEmpty && isEmpty && !isLoading) {
        return <></>;
    }

    return (
        <>
            <Spacing factor={1 / 2} />
            <HeaderThreeOnboard style={{ textAlign: 'left' }}>Agenda Quick Launch</HeaderThreeOnboard>
            <Spacing factor={1 / 2} />
            <Box display="flex" flexDirection={isDesktopView ? 'row' : 'column'} flexWrap="wrap" position="relative">
                {isLoading ? (
                    <LoadingQuickSectionPlaceholder />
                ) : isEmpty ? (
                    <Box
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Standup App Demo From Empty Quick Launch Section',
                            });
                            const success = URLUtil.openURL(`${window.location.origin}${ClientPath.Demo}`);
                            if (!success) {
                                routeToDemoExperience();
                            }
                        }}
                        minHeight="83px"
                        position="relative"
                        display="flex"
                        flexDirection="column"
                        paddingTop="20px"
                        paddingBottom="15px"
                        paddingX="20px"
                        style={{
                            background: lightTheme.actionCardPlaceholder.gradient,
                            cursor: 'pointer',
                            marginBottom: '20px',
                            marginRight: '20px',
                            borderRadius: '15px',
                            width: isDesktopView ? '25%' : undefined,
                            flex: !isDesktopView ? '1' : undefined,
                            border: `2px dashed ${lightTheme.actionCard.border}`,
                        }}
                    >
                        <Box color={lightTheme.primary.midnight} fontWeight="bold" fontSize="22px">
                            {'You have no In-meeting Agendas'}
                        </Box>
                        <Box fontSize="16px" mt="2px">
                            {'Enable in your meeting settings'}
                        </Box>
                        <Box display="flex" flexDirection="Row" mt="10px">
                            <Box color={lightTheme.primary.green} fontWeight="bold">
                                {'Launch Demo'}
                            </Box>
                            <ArrowForwardIos
                                style={{ width: '20px', height: '20px', color: lightTheme.primary.green }}
                            />
                        </Box>
                    </Box>
                ) : (
                    Object.values(topUniqueSeries).map(({ series, nextEvent }) => {
                        return (
                            <Box
                                key={series.id}
                                onClick={() => {
                                    openInMeetingApp(series, 'Facilitation quick launch');
                                }}
                                minHeight="85px"
                                position="relative"
                                display="flex"
                                flexDirection="column"
                                paddingTop="20px"
                                paddingBottom="15px"
                                paddingX="20px"
                                style={{
                                    background: lightTheme.actionCard.gradient,
                                    cursor: 'pointer',
                                    marginBottom: '20px',
                                    marginRight: '20px',
                                    borderRadius: '15px',
                                    width: isDesktopView ? '25%' : undefined,
                                    flex: !isDesktopView ? '1' : undefined,
                                    border: `1px solid ${lightTheme.actionCard.border}`,
                                }}
                            >
                                <Box color={lightTheme.primary.midnight} fontWeight="bold" fontSize="22px">
                                    {series.meetingTitle}
                                </Box>
                                <Box fontSize="16px" mt="2px">{`${moment(nextEvent.start?.dateTime).format(
                                    'h:mmA'
                                )}`}</Box>
                                <Box display="flex" flexDirection="Row" mt="10px">
                                    <Box color={lightTheme.primary.green} fontWeight="bold">
                                        {'Launch Agenda'}
                                    </Box>
                                    <ArrowForwardIos
                                        style={{ width: '20px', height: '20px', color: lightTheme.primary.green }}
                                    />
                                </Box>
                                <FacilicationViz
                                    style={{
                                        overflow: 'hidden',
                                        borderRadius: '15px',
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                    }}
                                />
                            </Box>
                        );
                    })
                )}
            </Box>
        </>
    );
};
