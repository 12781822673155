import { Box, Button, ButtonGroup, CircularProgress, IconButton, TextField, Tooltip } from '@material-ui/core';
import { Cancel, CheckCircle, Close, CloudDownload, Edit, Folder, InfoOutlined } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import React, { Ref } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { DashboardSection, demoBotId } from '@spinach-shared/constants';
import {
    ClientEventType,
    FeatureToggle,
    Media,
    MeetingSelection,
    SummaryJson,
    TranscriptData,
} from '@spinach-shared/types';
import { SpinachAPIPath } from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import { patchSpinachAPI } from '../../../../apis/patchSpinachAPI';
import { ElementId } from '../../../../constants';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useIsMeetingVideoDownload,
    useIsMeetingVideoPlayer,
    useProFeatures,
    useWindowSize,
} from '../../../../hooks';
import { LocalStorageKeys, useLocalStorage } from '../../../../hooks/useLocalStorage';
import { lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { getClientConfigValue } from '../../../../utils/getClientConfigValue';
import { Anchor, Spacing } from '../../../common';
import { ScrollArea } from '../../../stand-up';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import { AskSpinach, AskSpinachFrom } from './AskSpinach';
import { HighlightsPlayer } from './HighlightsPlayer';
import { getBlurbId } from './TranscriptsSection';

const nonBreakableSpace = '\u00A0';

export type MeetingMediaProps = {
    onDownloadVideo: () => void;
    meetingSelection: MeetingSelection;
    stackVideoLayout: boolean;
    freeOptionsCodes: string[];
    videoRef: React.RefObject<HTMLVideoElement>;
    dashboardSection: DashboardSection;
    onTimeUpdate: (time: number) => void;
    media?: Media;
    transcript?: TranscriptData;
    minimalTranscriptResponse: string[] | null;
    botId: string;
    seriesId?: string;
    onTitleUpdate?: (updatedSelection: MeetingSelection) => void;
    summaryJson: SummaryJson | null;
    videoMode: 'full' | 'highlights';
    setVideoMode: (mode: 'full' | 'highlights') => void;
};

export function MeetingMedia({
    media,
    onDownloadVideo,
    stackVideoLayout,
    meetingSelection,
    freeOptionsCodes,
    videoRef,
    transcript,
    dashboardSection,
    minimalTranscriptResponse,
    onTimeUpdate,
    botId,
    seriesId,
    onTitleUpdate,
    summaryJson,
    videoMode,
    setVideoMode,
}: MeetingMediaProps) {
    const hasProFeatures = useProFeatures();
    const showUpgradeToPro = !hasProFeatures && !freeOptionsCodes.includes(meetingSelection.code);
    const canShowMedia = media && transcript;
    const [user] = useGlobalAuthedUser();

    return (
        <Box
            flex={stackVideoLayout ? 1 : 4}
            mr={stackVideoLayout ? undefined : '25px'}
            minWidth={0}
            display="flex"
            flexDirection="column"
        >
            {!user.featureToggles[FeatureToggle.UploadVideoToGoogleDrive] ? (
                <MediaDisabledSection stackVideoLayout={stackVideoLayout} media={media} />
            ) : showUpgradeToPro && !user.featureToggles[FeatureToggle.UploadVideoToGoogleDrive] ? (
                <UpgradeToProMediaSection />
            ) : canShowMedia ? (
                <MeetingMediaInner
                    media={media}
                    onDownloadVideo={onDownloadVideo}
                    stackVideoLayout={stackVideoLayout}
                    meetingSelection={meetingSelection}
                    videoRef={videoRef}
                    minimalTranscriptResponse={minimalTranscriptResponse}
                    transcript={transcript}
                    dashboardSection={dashboardSection}
                    onTimeUpdate={onTimeUpdate}
                    botId={botId}
                    seriesId={seriesId}
                    onTitleUpdate={onTitleUpdate}
                    summaryJson={summaryJson}
                    videoMode={videoMode}
                    setVideoMode={setVideoMode}
                />
            ) : (
                <></>
            )}
        </Box>
    );
}

export type MeetingMediaInnerProps = {
    media: Media;
    onDownloadVideo: () => void;
    stackVideoLayout: boolean;
    meetingSelection: MeetingSelection;
    videoRef: React.RefObject<HTMLVideoElement>;
    transcript: TranscriptData;
    dashboardSection: DashboardSection;
    onTimeUpdate: (time: number) => void;
    minimalTranscriptResponse: string[] | null;
    botId: string;
    seriesId?: string;
    onTitleUpdate?: (updatedSelection: MeetingSelection) => void;
    summaryJson: SummaryJson | null;
    videoMode: 'full' | 'highlights';
    setVideoMode: (mode: 'full' | 'highlights') => void;
};

export const MeetingMediaInner = ({
    media,
    onDownloadVideo,
    stackVideoLayout,
    meetingSelection: selection,
    videoRef,
    transcript,
    dashboardSection,
    onTimeUpdate,
    botId,
    seriesId,
    minimalTranscriptResponse,
    onTitleUpdate,
    summaryJson,
    videoMode,
    setVideoMode,
}: MeetingMediaInnerProps) => {
    const [user] = useGlobalAuthedUser();
    const [currentSecond, setSecond] = useState(0);
    const track = useExperienceTracking();
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [editedTitle, setEditedTitle] = useState(selection?.title || '');
    const { setToastText } = useGlobalAiDashboard();
    const { setState } = useGlobalAiDashboard();
    const isUploadedMeeting = botId.startsWith('upload-');
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [editedDate, setEditedDate] = useState<Date | null>(selection?.createdAt || null);
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    useEffect(() => {
        onTimeUpdate(currentSecond);
    }, [currentSecond, onTimeUpdate]);

    useEffect(() => {
        if (
            summaryJson?.clips &&
            summaryJson.clips.length > 0 &&
            !(user.featureToggles[FeatureToggle.HideClipsUI] ?? false)
        ) {
            const defaultToClips = user.featureToggles[FeatureToggle.DefaultMediaTypeClips] ?? false;
            setVideoMode(defaultToClips ? 'highlights' : 'full');
        } else {
            setVideoMode('full');
        }
    }, [summaryJson?.clips, setVideoMode, user.featureToggles]);

    const autoScrollTranscript = true;

    const activeBlurbIndex = useMemo(() => {
        if (!transcript || !Array.isArray(transcript)) {
            return undefined;
        }
        const activeBlurb = transcript.find(
            (blurb, i) =>
                currentSecond >= blurb.timestamp &&
                (transcript[i + 1] == undefined || currentSecond <= transcript[i + 1].timestamp)
        );
        return activeBlurb ? transcript?.indexOf(activeBlurb) : undefined ?? undefined;
    }, [currentSecond, transcript]);

    useEffect(() => {
        if (autoScrollTranscript && activeBlurbIndex) {
            const element = document.getElementById(getBlurbId(activeBlurbIndex));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [autoScrollTranscript, activeBlurbIndex, /* effect dep */ dashboardSection]);

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            setSecond(Math.floor(videoRef.current.currentTime));
        }
    };

    const onPlay: React.ReactEventHandler<HTMLVideoElement> = (player) => {
        if (!player.target) {
            return;
        }

        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Play Video',
        });

        const video = player.target as HTMLVideoElement;
        // check if current second is 0
        if (transcript && Array.isArray(transcript) && transcript.length) {
            // skip silence
            const start = Math.ceil(transcript[0].timestamp);
            if (video.currentTime < start) {
                video.currentTime = start;
            }
        }
    };

    const isVideoDisabled = media && 'isVideoRecordingDisabled' in media;
    const noUrl = media && 'recordingUrl' in media ? !media.recordingUrl : true;
    const noDriveUrl = media && 'googleDriveUrl' in media ? !media.googleDriveUrl : true;
    const isError = !!(media && 'error' in media) || (!isVideoDisabled && noUrl && noDriveUrl);

    const videoPlayerEnabled = useIsMeetingVideoPlayer();
    const videoDownloadEnabled = useIsMeetingVideoDownload();

    // TODO add stacked view as global reference so that we can prevent multi scroll experience on stacked view width
    const { isDesktopView, height } = useWindowSize();
    const [headerHeightState, setHeaderHeightState] = useState(0);

    const headerElement = document.getElementById(ElementId.MeetingNotesOverviewHeader);
    useEffect(() => {
        const height = headerElement?.getBoundingClientRect().height ?? 0;
        setHeaderHeightState(height);
    }, [headerElement?.getBoundingClientRect().height]);

    const handleEditTitleClick = () => {
        setIsEditingTitle(true);
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Edit Meeting Title',
        });
    };

    const handleSave = async () => {
        try {
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Save Meeting Title',
            });

            await patchSpinachAPI(SpinachAPIPath.UpdateMeetingMetadata.replace(':botId', selection.botId), {
                meetingTitle: editedTitle,
            });
            setIsEditingTitle(false);

            // Create updated selection object
            const updatedSelection = {
                ...selection,
                title: editedTitle,
                label: editedTitle,
            };

            // Update global state
            setState((prev) => ({
                ...prev,
                selection: updatedSelection,
                meetingOptions: prev.meetingOptions.map((item) => {
                    if (item.botId === selection.botId) {
                        return {
                            ...item,
                            title: editedTitle,
                            label: editedTitle,
                        };
                    }
                    return item;
                }),
            }));

            // Call the callback to update parent's selection state
            if (onTitleUpdate) {
                onTitleUpdate(updatedSelection);
            }

            setToastText('Meeting title updated successfully');
        } catch (error) {
            setToastText('Failed to update meeting title');
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Meeting Title Update Failed',
            });
        }
    };

    const handleCancel = () => {
        setEditedTitle(selection?.title || '');
        setIsEditingTitle(false);
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSave();
        } else if (event.key === 'Escape') {
            handleCancel();
        }
    };

    const handleDateSave = async () => {
        if (!editedDate) {
            return;
        }

        try {
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Save Meeting Date',
            });

            await patchSpinachAPI(SpinachAPIPath.UpdateMeetingMetadata.replace(':botId', selection.botId), {
                meetingDate: moment(editedDate).toISOString(),
            });
            setIsEditingDate(false);
            setDatePickerOpen(false);

            // Create updated selection object with new date
            const updatedSelection = {
                ...selection,
                createdAt: editedDate,
                verbalDate: moment(editedDate).format('dddd, MMM Do YYYY'),
            };

            // Update global state
            setState((prev) => ({
                ...prev,
                selection: updatedSelection,
                meetingOptions: prev.meetingOptions.map((item) => {
                    if (item.botId === selection.botId) {
                        return {
                            ...item,
                            createdAt: editedDate,
                            verbalDate: moment(editedDate).format('dddd, MMM Do YYYY'),
                        };
                    }
                    return item;
                }),
            }));

            // Use the callback for date updates
            if (onTitleUpdate) {
                onTitleUpdate(updatedSelection);
            }

            setToastText('Meeting date updated successfully');
        } catch (error) {
            setToastText('Failed to update meeting date');
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Meeting Date Update Failed',
            });
        }
    };

    const handleDateCancel = () => {
        setEditedDate(selection?.createdAt ? new Date(selection.createdAt) : null);
        setIsEditingDate(false);
        setDatePickerOpen(false);
    };

    return (
        <>
            <ScrollArea
                style={{ height: isDesktopView && !stackVideoLayout ? `${height - headerHeightState}px` : 'unset' }}
                sidePadding={0}
            >
                <Box display="flex" alignItems="center">
                    {isEditingTitle ? (
                        <>
                            <TextField
                                value={editedTitle}
                                onChange={(e) => setEditedTitle(e.target.value)}
                                onKeyDown={handleKeyPress}
                                autoFocus
                                fullWidth
                                inputProps={{
                                    style: {
                                        fontSize: '27px',
                                        fontWeight: 700,
                                        padding: '0px',
                                        height: '40px',
                                    },
                                }}
                                style={{
                                    minWidth: '300px',
                                }}
                            />
                            <IconButton
                                onClick={handleSave}
                                size="small"
                                style={{
                                    color: lightTheme.primary.greenLight,
                                    marginLeft: '8px',
                                }}
                            >
                                <CheckCircle />
                            </IconButton>
                            <IconButton
                                onClick={handleCancel}
                                size="small"
                                style={{
                                    color: lightTheme.status.negative,
                                    marginLeft: '4px',
                                }}
                            >
                                <Cancel />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <Box fontWeight={700} fontSize="27px">
                                {selection?.title}
                            </Box>
                            {isUploadedMeeting && !user.isAnonymous ? (
                                <IconButton
                                    onClick={handleEditTitleClick}
                                    size="small"
                                    style={{
                                        color: lightTheme.primary.greenLight,
                                        marginLeft: '8px',
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            ) : null}
                        </>
                    )}
                </Box>
                <Box display="flex" alignItems="center">
                    {isEditingDate ? (
                        <>
                            <KeyboardDatePicker
                                disableToolbar
                                value={editedDate}
                                onChange={(date) => setEditedDate(date)}
                                maxDate={new Date()}
                                disableFuture
                                autoOk
                                variant="inline"
                                format="yyyy-MM-dd"
                                margin="normal"
                                open={datePickerOpen}
                                onOpen={() => setDatePickerOpen(true)}
                                onClose={() => {
                                    setDatePickerOpen(false);
                                    if (!editedDate) {
                                        setIsEditingDate(false);
                                    }
                                }}
                                id="date-picker-inline"
                                InputProps={{
                                    style: {
                                        fontSize: '16px',
                                        fontWeight: 700,
                                    },
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <IconButton
                                onClick={handleDateSave}
                                size="small"
                                style={{
                                    color: lightTheme.primary.greenLight,
                                    marginLeft: '8px',
                                }}
                            >
                                <CheckCircle />
                            </IconButton>
                            <IconButton
                                onClick={handleDateCancel}
                                size="small"
                                style={{
                                    color: lightTheme.status.negative,
                                    marginLeft: '4px',
                                }}
                            >
                                <Cancel />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <Box fontWeight={700} fontSize="16px">
                                {selection?.verbalDate}
                            </Box>
                            {isUploadedMeeting && !user.isAnonymous ? (
                                <IconButton
                                    onClick={() => {
                                        setEditedDate(selection?.createdAt ? new Date(selection.createdAt) : null);
                                        setIsEditingDate(true);
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Edit Meeting Date',
                                        });
                                        setTimeout(() => {
                                            setDatePickerOpen(true);
                                        }, 0);
                                    }}
                                    size="small"
                                    style={{
                                        color: lightTheme.primary.greenLight,
                                        marginLeft: '8px',
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            ) : null}
                        </>
                    )}
                </Box>
                {videoPlayerEnabled ? (
                    <MediaComponent
                        meetingCreatedAt={selection.createdAt}
                        hasProFeatures={user.hasProFeatures}
                        loading={!!(media && 'loading' in media)}
                        error={isError}
                        recordingUrl={media && 'recordingUrl' in media ? media.recordingUrl ?? undefined : undefined}
                        driveUrl={media && 'googleDriveUrl' in media ? media.googleDriveUrl ?? undefined : undefined}
                        videoRef={videoRef}
                        isVideoDisabled={isVideoDisabled}
                        onTimeUpdate={handleTimeUpdate}
                        onPlay={onPlay}
                        summaryJson={summaryJson}
                        videoMode={videoMode}
                        setVideoMode={setVideoMode}
                        botId={botId}
                    />
                ) : null}
                {media && 'participantsNames' in media ? (
                    <Box fontSize="18px" mt={stackVideoLayout ? '5px' : '25px'}>
                        <Box display={stackVideoLayout ? 'contents' : undefined} fontWeight={700}>
                            {'Meeting Attendees: '}
                        </Box>
                        <Box display={stackVideoLayout ? 'contents' : undefined} fontSize="16px">
                            {media.participantsNames
                                .map((name) => name.replaceAll(/\s/g, nonBreakableSpace))
                                .join(', ')}
                        </Box>
                    </Box>
                ) : null}
                {videoDownloadEnabled && media && 'recordingUrl' in media && media.recordingUrl ? (
                    <Tooltip title={user.isPayingUser ? '' : 'Paid feature'} placement="right-start">
                        <Box
                            color={lightTheme.primary.greenLight}
                            display="flex"
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                alignSelf: 'flex-start',
                                opacity: user.hasProFeatures ? 1 : 0.4,
                            }}
                            marginTop="10px"
                            onClick={onDownloadVideo}
                        >
                            <CloudDownload style={{ marginRight: '10px' }} />
                            {'Download Video'}
                        </Box>
                    </Tooltip>
                ) : null}
                <Spacing />
                {!stackVideoLayout ? (
                    <AskSpinach
                        from={AskSpinachFrom.MediaComponent}
                        minimalTranscriptResponse={minimalTranscriptResponse}
                        botId={botId}
                        seriesId={seriesId}
                    />
                ) : (
                    <></>
                )}
            </ScrollArea>
        </>
    );
};

type VideoMode = 'full' | 'highlights';

const VideoToggle = ({ mode, onModeChange }: { mode: VideoMode; onModeChange: (mode: VideoMode) => void }) => {
    return (
        <ButtonGroup
            style={{
                marginBottom: '10px',
                borderRadius: '20px',
                overflow: 'hidden',
                border: `1px solid ${lightTheme.primary.greenLight}`,
            }}
            disableElevation
        >
            <Button
                variant={mode === 'highlights' ? 'contained' : 'outlined'}
                onClick={() => onModeChange('highlights')}
                style={{
                    backgroundColor: mode === 'highlights' ? lightTheme.primary.greenLight : 'transparent',
                    color: mode === 'highlights' ? 'white' : lightTheme.primary.greenLight,
                    borderRadius: '20px',
                    border: 'none',
                    minWidth: '100px',
                    padding: '6px 16px',
                }}
            >
                Highlights
            </Button>
            <Button
                variant={mode === 'full' ? 'contained' : 'outlined'}
                onClick={() => onModeChange('full')}
                style={{
                    backgroundColor: mode === 'full' ? lightTheme.primary.greenLight : 'transparent',
                    color: mode === 'full' ? 'white' : lightTheme.primary.greenLight,
                    borderRadius: '20px',
                    border: 'none',
                    minWidth: '100px',
                    padding: '6px 16px',
                }}
            >
                Full video
            </Button>
        </ButtonGroup>
    );
};

export function MediaComponent({
    meetingCreatedAt,
    hasProFeatures,
    loading,
    error,
    recordingUrl,
    driveUrl,
    videoRef,
    onTimeUpdate,
    onPlay,
    summaryJson,
    videoMode,
    setVideoMode,
    botId,
}: {
    meetingCreatedAt: Date;
    hasProFeatures: boolean;
    loading: boolean;
    error: boolean;
    isVideoDisabled: boolean;
    recordingUrl?: string;
    driveUrl?: string;
    videoRef: Ref<HTMLVideoElement>;
    onTimeUpdate: React.ReactEventHandler<HTMLVideoElement>;
    onPlay: React.ReactEventHandler<HTMLVideoElement>;
    summaryJson: SummaryJson | null;
    videoMode: 'full' | 'highlights';
    setVideoMode: (mode: 'full' | 'highlights') => void;
    botId: string;
}) {
    const [user] = useGlobalAuthedUser();
    const isDemoNotes = botId === demoBotId;
    return (
        <Box marginRight="5px" marginTop="5px">
            {loading ? (
                <CircularProgress
                    size={'30px'}
                    style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
                />
            ) : null}
            {error ? (
                <Box style={{ background: '#F0F0F0', borderRadius: '4px' }} display={'flex'} padding="20px">
                    <Folder style={{ width: '40px', height: '40px' }} htmlColor={'#CCC'} />
                    <Box color="black" marginLeft="15px" marginY="auto">
                        <ErrorComponent meetingCreatedAt={meetingCreatedAt} hasProFeatures={hasProFeatures} />
                    </Box>
                </Box>
            ) : null}
            {driveUrl ? (
                <Box style={{ background: '#F0F0F0', borderRadius: '4px' }} display={'flex'} padding="20px">
                    <Folder style={{ width: '40px', height: '40px' }} htmlColor={'#CCC'} />
                    <Box color="black" marginLeft="15px" marginY="auto">
                        Video recording available on <a href={driveUrl}>Google Drive</a>
                    </Box>
                </Box>
            ) : undefined}
            {recordingUrl ? (
                <>
                    {summaryJson?.clips &&
                    Array.isArray(summaryJson.clips) &&
                    summaryJson.clips.length > 0 &&
                    !(user.featureToggles[FeatureToggle.HideClipsUI] ?? false) ? (
                        <VideoToggle mode={videoMode} onModeChange={setVideoMode} />
                    ) : null}
                    {videoMode === 'highlights' &&
                    summaryJson?.clips &&
                    Array.isArray(summaryJson.clips) &&
                    summaryJson.clips.length > 0 &&
                    !(user.featureToggles[FeatureToggle.HideClipsUI] ?? false) ? (
                        <HighlightsPlayer
                            videoRef={videoRef as React.RefObject<HTMLVideoElement>}
                            onPlay={onPlay}
                            onTimeUpdate={onTimeUpdate}
                            recordingUrl={recordingUrl}
                            summaryJson={summaryJson}
                        />
                    ) : (
                        <video
                            onPlay={onPlay}
                            ref={videoRef}
                            onTimeUpdate={onTimeUpdate}
                            controlsList="nodownload"
                            style={{ width: '100%', height: 'auto' }}
                            controls
                            src={recordingUrl}
                            onContextMenu={(e) => e.preventDefault()}
                        />
                    )}
                </>
            ) : (
                <></>
            )}
            {!isDemoNotes && !user.hasProFeatures && !user.isAnonymous ? <LackOfGoogleDriveIntegrationWarning /> : null}
        </Box>
    );
}

const LackOfGoogleDriveIntegrationWarning = () => {
    const [user] = useGlobalAuthedUser();
    const navigateToUpgrade = useNavigateToUpgrade();
    const track = useExperienceTracking();
    const [hasDismissedGoogleDriveVideoBackupWarning, setHasUserSeenProvisioningInstructions] = useLocalStorage(
        LocalStorageKeys.HasDismissedGoogleDriveVideoBackupWarning,
        false
    );
    const isFreeUserWhoIsEnabledForGoogleDriveVideoUpload =
        !user.hasProFeatures && user.featureToggles[FeatureToggle.UploadVideoToGoogleDrive];
    const isProCustomerWhoIsEnabledForGoogleDriveVideoUploadButHasNotEnabledItOrYetToDismissTheWarning =
        (!user.isAuthedForGoogleDrive || (user.isAuthedForGoogleDrive && !user.metadata.uploadVideoToGoogleDrive)) &&
        user.hasProFeatures &&
        !hasDismissedGoogleDriveVideoBackupWarning &&
        user.featureToggles[FeatureToggle.UploadVideoToGoogleDrive];

    const handleLearnMoreClick = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Video Retention Learn More',
        });
        URLUtil.openURL(`${getClientConfigValue('INTERCOM_SUPPORT_URL')}/articles/10067927-video-retention-period`);
    };

    return (
        <>
            {isFreeUserWhoIsEnabledForGoogleDriveVideoUpload && !user.isAnonymous ? (
                <Box style={{ background: '#FEF2DE', borderRadius: '4px' }} display={'flex'} padding="20px" mt="10px">
                    <InfoOutlined style={{ width: '40px', height: '40px' }} htmlColor={'#FDD291'} />
                    <Box color="black" marginLeft="15px" marginY="auto" display="flex" flexDirection="column">
                        <Box>
                            Video recordings are saved for 7 days, upgrade to extend video storage.{' '}
                            <Anchor onClick={handleLearnMoreClick}>Find out more here</Anchor>
                        </Box>
                        <Box
                            style={{
                                background: lightTheme.primary.greenDark,
                                color: 'white',
                                cursor: 'pointer',
                                borderRadius: '4px',
                                marginTop: '10px',
                                padding: '5px 10px',
                                alignSelf: 'flex-end',
                                fontWeight: 'bold',
                            }}
                            onClick={() => {
                                navigateToUpgrade('Click Upgrade (Google Drive recording backup)');
                            }}
                        >
                            Upgrade
                        </Box>
                    </Box>
                </Box>
            ) : undefined}
            {isProCustomerWhoIsEnabledForGoogleDriveVideoUploadButHasNotEnabledItOrYetToDismissTheWarning ? (
                <Box
                    style={{ background: '#FEF2DE', borderRadius: '4px' }}
                    display={'flex'}
                    paddingBottom="20px"
                    mt="10px"
                    paddingLeft="20px"
                >
                    <Box display={'flex'} mt="20px" flex="1">
                        <InfoOutlined style={{ width: '40px', height: '40px' }} htmlColor={'#FDD291'} />
                        <Box color="black" marginLeft="15px" marginY="auto">
                            Recordings are saved for a limited time. Enable Google Drive to store your recordings
                            longer. <Anchor onClick={handleLearnMoreClick}>Find out more here</Anchor>
                        </Box>
                    </Box>
                    <Box
                        m="5px"
                        alignSelf="top"
                        style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Close Google Drive Backup Warning',
                            });
                            setHasUserSeenProvisioningInstructions(true);
                        }}
                    >
                        <Close style={{ width: '18px', height: '18px' }} htmlColor={lightTheme.primary.greenDark} />
                    </Box>
                </Box>
            ) : undefined}
        </>
    );
};

const weekInMs = 1000 * 60 * 60 * 24 * 7;

const ErrorComponent = ({ meetingCreatedAt, hasProFeatures }: { meetingCreatedAt: Date; hasProFeatures: boolean }) => {
    const recallStillStoreThisVideo = Date.now() < meetingCreatedAt.getTime() + weekInMs;
    if (recallStillStoreThisVideo) {
        return <>{'Video is not available due to technical issues. Our team is working to resolve this.'}</>;
    } else {
        if (hasProFeatures) {
            return (
                <>{'Recordings are saved for a limited time. Enable Google Drive to store your recordings longer.'}</>
            );
        } else {
            return <>{'Video has expired. Upgrade to extend videos in the future.'}</>;
        }
    }
};

export const UpgradeToProMediaSection = () => {
    const navigateToUpgrade = useNavigateToUpgrade();

    return (
        <Box style={{ background: '#F0F0F0', borderRadius: '4px' }} display={'flex'} padding="20px">
            <Folder style={{ width: '40px', height: '40px' }} htmlColor={'#CCC'} />
            <Box color="black" marginLeft="15px" marginY="auto">
                <span>
                    {'Enjoy access to recordings for up to '}
                    <b>{`3${nonBreakableSpace}meetings`}</b>
                    {` on the free plan. Extend access to 7${nonBreakableSpace}days by `}
                    <Anchor
                        onClick={() => {
                            navigateToUpgrade('Click Upgrade (Link where video should be)');
                        }}
                    >
                        {'Upgrading'}
                    </Anchor>
                </span>
            </Box>
        </Box>
    );
};

export const MediaDisabledSection = ({
    media,
    stackVideoLayout,
}: {
    media: Media | undefined;
    stackVideoLayout: boolean;
}) => {
    const navigateToUpgrade = useNavigateToUpgrade();

    return (
        <>
            <Box style={{ background: '#F0F0F0', borderRadius: '4px' }} display={'flex'} padding="20px">
                <Folder style={{ width: '40px', height: '40px' }} htmlColor={'#CCC'} />
                <Box color="black" marginLeft="15px" marginY="auto">
                    <span>
                        <Anchor
                            onClick={() => {
                                navigateToUpgrade('Click Upgrade (partial summary disabled video)');
                            }}
                        >
                            {'Upgrade'}
                        </Anchor>{' '}
                        to access and download video (available for 7 days from recording date)
                    </span>
                </Box>
            </Box>
            {media && 'participantsNames' in media ? (
                <Box fontSize="18px" mt={stackVideoLayout ? '5px' : '25px'}>
                    <Box display={stackVideoLayout ? 'contents' : undefined} fontWeight={700}>
                        {'Meeting Attendees: '}
                    </Box>
                    <Box display={stackVideoLayout ? 'contents' : undefined} fontSize="16px">
                        {media.participantsNames.map((name) => name.replaceAll(/\s/g, nonBreakableSpace)).join(', ')}
                    </Box>
                </Box>
            ) : null}
        </>
    );
};
