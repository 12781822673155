import { ReactNode } from 'react';
import { atom } from 'recoil';

import { DashboardSection } from '@spinach-shared/constants';
import { ISOString, MeetingSelection } from '@spinach-shared/types';

export type GlobalAiDashboardState = {
    activeSection: DashboardSection;
    hasEditedSummary: boolean;
    toastText: ReactNode;
    isLoadingMeetingNotesDeletion: boolean;
    lastRefreshedHistory: ISOString;
    reportedEventIds: string[];
    isOnDemoNotes: boolean;
    meetingOptions: MeetingSelection[];
    isSearchVisible: boolean;
    pendingSpinachToggleICalUid?: string;
    pendingBotId?: string;
    isLoadingPendingBot: boolean;
    audioRecordingBlob?: Blob;
    recordingError?: string | null;
};

export const atomAiDashboardState = atom<GlobalAiDashboardState>({
    key: 'aiDashboard',
    default: {
        activeSection: DashboardSection.Summaries,
        hasEditedSummary: false,
        toastText: '',
        isLoadingMeetingNotesDeletion: false,
        lastRefreshedHistory: new Date().toISOString(),
        reportedEventIds: [],
        isOnDemoNotes: false,
        meetingOptions: [],
        isSearchVisible: false,
        pendingSpinachToggleICalUid: undefined,
        pendingBotId: undefined,
        isLoadingPendingBot: false,
    },
});
